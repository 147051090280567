import React from 'react';

import InfoBlock from '../blocks/InfoBlock';
import RectangleLink from '../components/RectangleLink';
import SEO from '../components/seo';
import Title from '../components/typography/Title';
import Layout from '../layout/Layout';
import { isBrowser } from '../utils/isBrowser';

import enMessages from '../i18n/money-transfer/en.json';
import ruMessages from '../i18n/money-transfer/ru.json';

const messages = {
    en: enMessages,
    ru: ruMessages,
};

const MoneyTransferPage = (props) => {
  const { pathContext: { locale } } = props;

  if (isBrowser && locale === 'en') {
    window.location.replace('/en/404/');
  }

  const docs = messages[locale].documents;

  return (
    <Layout locale={locale}>
      <SEO
        description={messages[locale].seo.description}
        title={messages[locale].seo.title}
      />

      <InfoBlock
        config={{
          className: 'Flex-row',
        }}
        configBlock={{
          baseBorder: true,
        }}
      >
        <div className="LegalInformation">
          <div className="LegalInformation-container">
            <div className="Title-Menu">
              <Title
                className="Block-Title"
                color="none"
                headerStyleNumb={'7'}
                innerHTML={messages[locale].title}
              />
            </div>

            <div>
              {docs.map((doc) => (
                <RectangleLink
                  key={doc.link}
                  label={doc.label}
                  link={doc.link}
                  target="_blank"
                  title={doc.title}
                />
              ))}
            </div>
          </div>
        </div>
      </InfoBlock>
    </Layout>
  );
};

export default MoneyTransferPage;
