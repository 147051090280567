import React from 'react';
import PropTypes from 'prop-types';
import Block, {BlockPropTypeConfig} from "./Block";
import BlockSection from "./BlockSection";

const InfoBlock = ({children, configBlock = {}, config = {}}) => {;
    return (
        <Block className='Block_theme_legalInformation' config={configBlock}>
            <div className="container">
                <BlockSection className={config.className}>
                    {children}
                </BlockSection>
            </div>
        </Block>
    )
};

export const InfoBlockPropTypes = {
    children: PropTypes.node,
    configBlock: BlockPropTypeConfig,
    config: PropTypes.object,
};

InfoBlock.propTypes = InfoBlockPropTypes;

export default InfoBlock;
